<template>
  <div class="order-center">
    <div v-if="!isShowImg" class="order_data">
      <div class="data_top_box">
        <div class="select_box">
          图片
          <!-- <el-checkbox @change='choose'>全选</el-checkbox> -->
        </div>
        <div class="serial_box">商品</div>
        <div class="place_date_box">单价（元）</div>
        <div class="number_box">数量</div>
        <div class="money_box">小计（元）</div>
        <div class="txt">操作</div>
      </div>
      <div v-for="(v, index) of list" :key="index">
        <div class="data_bottom_box">
          <div class="list">
            <div class="item_box_left">
              <div class="img_img_box">
                <!-- <el-checkbox v-model="checked"/> -->
                <img
                  @click="
                    $router.push({
                      name: 'CopyrightPracticeDetail',
                      params: { id: v.productId },
                    })
                  "
                  class="item_img"
                  :src="
                    v.fileUrl
                      ? $store.state.onlineBasePath + v.fileUrl
                      : require('assets/images/no-shop-img80.png')
                  "
                />
              </div>
              <div class="img_name_box">
                <div
                  @click="
                    $router.push({
                      name: 'CopyrightPracticeDetail',
                      params: { id: v.productId },
                    })
                  "
                  class="item_name"
                  v-html="v.productName"
                ></div>
              </div>
              <div class="item_date">￥{{ v.productPrices }}</div>
              <div class="item_sum" v-html="v.productNum"></div>
              <div class="item_money">
                ￥{{ v.productPrices * v.productNum }}
              </div>

              <div class="item_box_right">
                <div class="order_delete" @click="prompt(v.productId)">
                  删除
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="xian"></div>
      </div>
      <!-- <div class="close_choose" @click="deletev">删除选中商品</div> -->
    </div>

    <div v-if="!isShowImg" class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-size="limit"
        :current-page="page"
        @prev-click="lastPageChange"
        @next-click="nextPageChange"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>

    <div v-if="success" class="success">
      <img src="../../../../assets/images/successcl.png" />
      <span>已删除所选商品</span>
    </div>

    <div v-show="isShowImg" class="noOrder">
      <img :src="require('assets/images/no-shop.png')" alt="" />
      暂无收藏
    </div>
  </div>
</template>

<script>
import { collectList, addOrDel } from "api/copyrightService";
export default {
  name: "MyCollection",
  data() {
    return {
      //页码
      page: 1,
      //一页10条数据
      limit: 10,
      //总条数
      total: 0,
      // 收藏列表
      list: [],
      // 暂无收藏
      isShowImg: false,
      //全选标记
      checked: false,
      //删除成功
      success: false,
    };
  },
  methods: {
    //全选
    // choose(){
    // 	if(event.target.checked){
    // 		this.checked = true;
    // 	}else{
    // 		this.checked = false;
    // 	}
    // },
    // deletev(){
    // 	console.log(11111)
    // },
    // 获取收藏列表
    async getList() {
      let data = {
        page: this.page,
        limit: this.limit,
      };
      const res = await collectList(data);
      if (res.code == 200) {
        this.list = res.data.data;
        this.total = res.data.total;
        if (this.list.length == 0) {
          this.isShowImg = true;
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    prompt(i) {
      this.$confirm("确定要取消收藏该商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.remove(i);
      });
    },
    async remove(i) {
      let data = {
        productId: i,
      };
      let res = await addOrDel(data);
      if (res.code == 200) {
        this.success = true;
        this.getList();
        setTimeout(this.close, 3000);
      }
    },
    close() {
      this.success = false;
    },
    //  上一页
    lastPageChange(val) {
      this.page = val;
    },
    // 下一页
    nextPageChange(val) {
      this.page = val;
    },
    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getList();
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination {
  button {
    width: 80px;
  }
}
.success {
  width: 186px;
  height: 53px;
  background: #ffffff;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 27px;
  box-sizing: border-box;
  padding: 19px 21px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 88px;
  left: 790px;
  span {
    width: 114px;
    height: 16px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 26px;
    margin-left: 10px;
    margin-top: -5px;
  }
}
.close_choose {
  height: 12px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff6900;
  line-height: 18px;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.xian {
  width: 1050px;
  height: 1px;
  background: #f9f9f9;
}
.order-center {
  .title_box {
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title_txt {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }

    .refresh {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;

      .icon {
        width: 15px;
        height: 13px;
        @include backgroundGroup("../../../../assets/images/refresh-gray.png");
        margin-right: 10px;
      }

      .refresh_txt {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
  }

  .order_data {
    .data_top_box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8f8f8;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 800;
      padding: 10px 0;
      .select_box {
        padding: 0 0 0 20px;
        //flex:20%;
        width: 150px;
        input {
          margin-right: 10px;
        }
      }
      .serial_box {
        //flex: 15%;
        //width: 50px;
        width: 190px;
        text-align: center;

        .color_txt {
          color: #ff6900;
        }
      }

      .place_date_box {
        width: 200px;
        text-align: center;
      }

      .number_box {
        width: 300px;

        text-align: center;
      }

      .money_box {
        //flex: 1%;
        flex: 1;

        text-align: center;
      }

      .txt {
        //flex: 2%;
        width: 10%;

        text-align: center;
      }
    }

    .data_bottom_box {
      display: flex;
      //justify-content: space-between;
      //align-items: center;
      font-size: 14px;

      .list {
        flex: 1;
      }

      .item_box_left {
        padding: 20px 0;
        flex: 1;
        display: flex;
        //align-items: center;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border-bottom: none;
        }

        .img_img_box {
          width: 150px;
          display: flex;
          padding: 0 0 0 20px;

          .item_img {
            cursor: pointer;
            width: 80px;
            height: 80px;

            //margin-left: 10px;
          }
        }

        .img_name_box {
          width: 190px;
          cursor: pointer;
        }
        .item_name {
          text-align: center;
        }
        .item_date {
          width: 200px;
          text-align: center;
        }

        .item_sum {
          width: 300px;
          text-align: center;
        }

        .item_money {
          flex: 15%;
          color: red;
          text-align: center;
        }
      }

      .item_box_right {
        // width: 205px;
        width: 10%;
        text-align: center;

        .order_delete {
          cursor: pointer;
        }
      }
    }
  }

  .pagination {
    margin: 40px;

    .el-pagination {
      text-align: center;
    }
  }

  .noOrder {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 220px 0;

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
